import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import Header from "../Header/index";
import SideBar from "../SideBar/index";

class ExpertGallary extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      id: "",
      selectedImages: [],
      selectedWorkingImages: [],
      successMsg: "",
      errorMsg: "",
      token: retrievedObject ? retrievedObject.token : "",
      id: "",
      user_work_images: [],
      user_profile_images: [],
      expert_profile_videos: [],
    };
  }

  componentDidMount() {
    const userid = window.location.pathname.split("/").pop();
    this.getUserResults(userid);
  }

  getUserResults = async (userid) => {
    try {
      const { token } = this.state;
      const { AdminExpertUserDetails } = adminendPoints;
      const expertUrl = `${AdminExpertUserDetails}`;
      const contentType = "application/json";
      const body = {
        userid,
      };

      const response = await APICaller(
        expertUrl,
        "POST",
        body,
        contentType,
        token
      );
      const { status, user } = response.data;

      if (user) {
        const userWorkImages = user.user_work_images;
        const userProfileImages = user.user_profile_images;
        const expertProfileVideos = user.expert_profile_videos;

        this.setState({
          id: userid,
          user_work_images: userWorkImages,
          user_profile_images: userProfileImages,
          expert_profile_videos: expertProfileVideos,
        });
      } else {
        this.setState({
          errorMsg: "Failed to create City. Please try again.",
          successMsg: "",
        });
      }
    } catch (error) {
      console.log("error making data call --> ", error);
    }
  };

  handleImageChange = (e) => {
    const selectedImagesArray = Array.from(e.target.files);
    const selectedImagesWithFeatured = selectedImagesArray.map(
      (image, index) => ({
        file: image,
        is_featured: index === 0 ? "1" : "0",
      })
    );

    this.setState({ selectedImages: selectedImagesWithFeatured });
  };

  handleWorkingImageChange = (e) => {
    const selectedWorkingImagesArray = Array.from(e.target.files);
    const selectedWorkingImagesWithFeatured = selectedWorkingImagesArray.map(
      (image, index) => ({
        file: image,
        is_featured: index === 0 ? "1" : "0",
      })
    );

    this.setState({ selectedWorkingImages: selectedWorkingImagesWithFeatured });
  };

  handleFeaturedChange = (index, type) => {
    if (type === "image") {
      const updatedImages = this.state.selectedImages.map((image, i) => ({
        ...image,
        is_featured: i === index ? "1" : "0",
      }));
      this.setState({ selectedImages: updatedImages });
    } else if (type === "workingImage") {
      const updatedWorkingImages = this.state.selectedWorkingImages.map(
        (image, i) => ({
          ...image,
          is_featured: i === index ? "1" : "0",
        })
      );
      this.setState({ selectedWorkingImages: updatedWorkingImages });
    }
  };

  validateForm = () => {
    const { selectedWorkingImages } = this.state;

    const newErrors = {};

    if (selectedWorkingImages.length === 0) {
      newErrors.user_work_images = "Expert work Image is required.";
    }

    return newErrors;
  };

  handleOpenDeleteModal = (itemId) => {
    this.setState({ showDeleteModal: true, deleteItemId: itemId });
  };

  handleCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false, deleteItemId: null });
  };

  handleConfirmDelete = async () => {
    const { deleteItemId } = this.state;
    this.handleCloseDeleteModal();
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });

      const { selectedImages, selectedWorkingImages, token, id } = this.state;

      const { AdminUploadExpertGallery } = adminendPoints;
      const formData = new FormData();
      formData.append("id", id);

      for (let i = 0; i < selectedImages.length; i++) {
        formData.append("user_profile_images", selectedImages[i].file);
        formData.append(
          "user_profile_images_featured",
          selectedImages[i].is_featured
        );
      }

      for (let i = 0; i < selectedWorkingImages.length; i++) {
        formData.append("user_work_images", selectedWorkingImages[i].file);
        formData.append(
          "user_work_images_featured",
          selectedWorkingImages[i].is_featured
        );
      }

      console.log(formData, "formData");

      const method = "POST";
      const body = formData;

      try {
        const response = await APICaller(
          AdminUploadExpertGallery,
          method,
          body,
          {},
          token
        );

        const { status } = response.data;

        if (status === 200) {
          localStorage.setItem("activeTab", this.props.activeTab);
          this.setState(
            {
              successMsg: "Images Added successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "" });
                window.location.reload();
              }, 5000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to update images. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the category. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  handleDeleteImage = (imageId) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      this.handleDeleteImageAPI(imageId);
    }
  };

  handleDeleteImageAPI = async (imageId) => {
    const { token, id } = this.state;

    const { AdminDeleteUserProfileImage } = adminendPoints;
    const method = "POST";
    const body = {
      id: imageId,
      userid: id,
    };
    const contentType = "application/json";

    try {
      const response = await APICaller(
        AdminDeleteUserProfileImage,
        method,
        body,
        contentType,
        token
      );
      if (response.status === 200) {
        localStorage.setItem("activeTab", this.props.activeTab);
        this.props.handleTabChange("section5");
        window.location.reload();
      } else {
        console.log("Error deleting image");
      }
    } catch (error) {
      console.log("Error making API call:", error);
    }
  };

  handleDeleteWordkingImage = (wimageId) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      this.handleDeleteWordkingImageAPI(wimageId);
    }
  };

  handleDeleteWordkingImageAPI = async (wimageId) => {
    const { token, id } = this.state;

    const { AdminDeleteUserWorkingImage } = adminendPoints;
    const method = "POST";
    const body = {
      id: wimageId,
      userid: id,
    };
    const contentType = "application/json";

    try {
      const response = await APICaller(
        AdminDeleteUserWorkingImage,
        method,
        body,
        contentType,
        token
      );
      if (response.status === 200) {
        localStorage.setItem("activeTab", this.props.activeTab);
        this.props.handleTabChange("section5");
        window.location.reload();
      } else {
        console.log("Error deleting image");
      }
    } catch (error) {
      console.log("Error making API call:", error);
    }
  };

  render() {
    const {
      id,
      selectedImages,
      selectedWorkingImages,
      errors,
      redirect,
      successMsg,
      errorMsg,
      token,
      user_work_images,
      user_profile_images,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/ManageExperts" />;
    }

    const PROD_BUCKET_URL = process.env.REACT_APP_PROD_BUCKET_URL;

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <form onSubmit={this.handleSubmit}>
            <div className="">
              {errorMsg && (
                <div className="alert alert-danger alert-dismissible">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-hidden="true"
                  >
                    &times;
                  </button>
                  <h5>
                    <i className="icon fas fa-ban"></i> Alert!
                  </h5>
                  {errorMsg}
                </div>
              )}
              {successMsg && (
                <div className="alert alert-success alert-dismissible">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                  <h5>
                    <i className="icon fas fa-check"></i> Alert!
                  </h5>
                  {successMsg}
                </div>
              )}

              {errors && (
                <div className="callout callout-danger">
                  <h5>All * fields are required</h5>
                  {errors.user_profile_images && (
                    <p className="error">{errors.user_profile_images}</p>
                  )}
                  {errors.user_work_images && (
                    <p className="error">{errors.user_work_images}</p>
                  )}
                </div>
              )}
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="user_profile_images">
                      User Profile Images
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="user_profile_images"
                      name="user_profile_images"
                      accept="image/*"
                      onChange={this.handleImageChange}
                      multiple
                    />
                  </div>
                  <div className="row">
                    {selectedImages.map((image, index) => (
                      <div className="col-sm-6 image-perview" key={index}>
                        <figure>
                          <img
                            src={URL.createObjectURL(image.file)}
                            alt={`SelectedImage ${index + 1}`}
                            style={{
                              maxWidth: "200px",
                              maxHeight: "200px",
                            }}
                          />
                        </figure>
                        <label>
                          <input
                            type="radio"
                            name={`featuredImage`}
                            checked={image.is_featured === "1"}
                            onChange={() =>
                              this.handleFeaturedChange(index, "image")
                            }
                          />
                          Featured Image
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="fileName">User Working Images</label>
                    <input
                      type="file"
                      className="form-control"
                      id="user_work_images"
                      name="user_work_images"
                      accept="image/*"
                      onChange={this.handleWorkingImageChange}
                      multiple
                    />
                  </div>
                  <div className="row">
                    {selectedWorkingImages.map((image, index) => (
                      <div className="col-sm-6 image-perview" key={index}>
                        <figure>
                          <img
                            src={URL.createObjectURL(image.file)}
                            alt={`SelectedWorkingImage ${index + 1}`}
                            style={{
                              maxWidth: "200px",
                              maxHeight: "200px",
                            }}
                          />
                        </figure>
                        <label>
                          <input
                            type="radio"
                            name="featuredWorkingImage"
                            checked={image.is_featured === "1"}
                            onChange={() =>
                              this.handleFeaturedChange(index, "workingImage")
                            }
                          />
                          Featured Working Image
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>

          <div className="row image-gallery">
            {user_profile_images.length > 0 ? (
              <div>
                <h5 className="mt-4">User Profile Images</h5>
                <hr />
                <div className="row image-gallery">
                  {user_profile_images.map((image, index) => (
                    <div className="col-sm-2" key={index}>
                      <div className="image-gallery-item">
                        <a
                          href={`https://via.placeholder.com/1200/FFFFFF.png?text=${
                            index + 1
                          }`}
                          data-toggle="lightbox"
                          data-title={`sample ${index + 1} - white`}
                          data-gallery="gallery"
                        >
                          <img
                            src={`${PROD_BUCKET_URL}/${image.image}`}
                            className="img-fluid mb-3"
                            alt={`${image.image}`}
                          />
                        </a>
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="remove"
                          onClick={() => this.handleDeleteImage(image._id)}
                        >
                          <i className="fas fa-times"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div>
                <h5 className="mt-4">User Profile Images</h5>
                <hr />
                <p>No images found.</p>
              </div>
            )}
          </div>

          <div className="row image-gallery">
            {user_work_images.length > 0 ? (
              <div>
                <h5 className="mt-4">User Work Images</h5>
                <hr />
                <div className="row image-gallery">
                  {user_work_images.map((images, index) => (
                    <div className="col-sm-2" key={index}>
                      <div className="image-gallery-item">
                        <a
                          href={`https://via.placeholder.com/1200/FFFFFF.png?text=${
                            index + 1
                          }`}
                          data-toggle="lightbox"
                          data-title={`sample ${index + 1} - white`}
                          data-gallery="gallery"
                        >
                          <img
                            src={`${PROD_BUCKET_URL}/${images.image}`}
                            className="img-fluid mb-3"
                            alt={`${images.image}`}
                          />
                        </a>
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="remove"
                          onClick={() =>
                            this.handleDeleteWordkingImage(images._id)
                          }
                        >
                          <i className="fas fa-times"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div>
                <h5 className="mt-4">User Work Images</h5>
                <hr />
                <p>No images found.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStateToProps)(ExpertGallary);
