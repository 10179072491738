import React from "react";
import { Link, useLocation } from "react-router-dom";
import { getLocalStorageItem } from "../../store/localStorage";

const SideBar = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  var retrievedObject = getLocalStorageItem("myStylistAdmin");

  return (
    <aside className="main-left-sidebar">
      <div className="logo mb-2">
        <Link to="#">
          <img
            src={process.env.PUBLIC_URL + "/assets/img/logo.svg"}
            alt="Logo"
            title=""
          />
        </Link>
      </div>
      <div className="left-menu-main flex-fill">
        <h6 className="menu-title">Main</h6>
        <ul className="left-menu">
          <NavItem
            to="/Home"
            icon="assets/img/icon-dashboard.svg"
            text="Dashboard"
            isActive={splitLocation[1] === ""}
          />
          <NavItem
            to="/ManageExperts"
            icon="assets/img/icon-stylists-management.svg"
            text="Stylists Management"
            isActive={splitLocation[1] === "ManageExperts"}
          />
          {/* <NavItem
            to="/ManageCategory"
            icon="assets/img/icon-category-management.svg"
            text="Category Management"
            isActive={splitLocation[1] === "ManageCategory"}
          /> */}
          <NavItem
            to="/ManageServices"
            icon="assets/img/icon-services-management.svg"
            text="Menu Management"
            isActive={splitLocation[1] === "ManageServices"}
          />
        </ul>

        <h6 className="menu-title">Business Management</h6>
        <ul className="left-menu">
          <NavItem
            to="/ManageOffers"
            icon="assets/img/icon-offer.svg"
            text="Offer"
            isActive={splitLocation[1] === "ManageOffers"}
          />
          <NavItem
            to="/ManagePackages"
            icon="assets/img/icon-package.svg"
            text="Package"
            isActive={splitLocation[1] === "ManagePackages"}
          />
          <NavItem
            to="/ManageCampaigns"
            icon="assets/img/icon-campaign.svg"
            text="Campaign"
            isActive={splitLocation[1] === "ManageCampaigns"}
          />
          <NavItem
            to="/ManageUserJobs"
            icon="assets/img/icon-user-jobs.svg"
            text="User Jobs"
            isActive={splitLocation[1] === "ManageUserJobs"}
          />

          <NavItem
            to="/ManageHomeBanners"
            icon="assets/img/icon-user-jobs.svg"
            text="Home Banners"
            isActive={splitLocation[1] === "ManageHomeBanners"}
          />
        </ul>

        <h6 className="menu-title">Admin Approval</h6>
        <ul className="left-menu">
          <NavItem
            to="/StylistGallery"
            icon="assets/img/icon-gallery.svg"
            text="Stylist gallery"
            isActive={splitLocation[1] === "StylistGallery"}
          />
          <NavItem
            to="/SaloonGallery"
            icon="assets/img/icon-gallery.svg"
            text="Saloon gallery"
            isActive={splitLocation[1] === "SaloonGallery"}
          />
          {/* Add other list items in a similar manner */}
        </ul>

        {/* Add the remaining sections similarly */}

        <h6 className="menu-title">Chat Supervision</h6>
        <ul className="left-menu">
          <NavItem
            to="/ExpertInbox"
            icon="assets/img/iocn-experts-inbox.svg"
            text="Experts inbox"
            isActive={splitLocation[1] === "ExpertInbox"}
          />
          <NavItem
            to="/UserInbox"
            icon="assets/img/icon-user.svg"
            text="User Inbox"
            isActive={splitLocation[1] === "UserInbox"}
          />
        </ul>

        <h6 className="menu-title">Notification Management</h6>
        <ul className="left-menu">
          <NavItem
            to="/StylistBulkNotification"
            icon="assets/img/icon-push-notification.svg"
            text="User Push Notification"
            isActive={splitLocation[1] === "StylistBulkNotification"}
          />
          <NavItem
            to="/BulkNotification"
            icon="assets/img/icon-push-notification.svg"
            text="Stylist Push Notification"
            isActive={splitLocation[1] === "BulkNotification"}
          />
        </ul>

        <h6 className="menu-title">Reports</h6>
        <ul className="left-menu">
          <NavItem
            to="/MyReports"
            icon="assets/img/icon-my-reports.svg"
            text="My Reports"
            isActive={splitLocation[1] === "MyReports"}
          />
        </ul>

        <h6 className="menu-title">Appointment Management</h6>
        <ul className="left-menu">
          <NavItem
            to="/ManageAppointments"
            icon="assets/img/icon-appointment.svg"
            text="Appointment"
            isActive={splitLocation[1] === "ManageAppointments"}
          />
        </ul>

        <h6 className="menu-title">Location Management</h6>
        <ul className="left-menu">
          <NavItem
            to="/ManageState"
            icon="assets/img/icon-push-notification.svg"
            text="State"
            isActive={splitLocation[1] === "ManageState"}
          />

          <NavItem
            to="/ManageCity"
            icon="assets/img/icon-push-notification.svg"
            text="City"
            isActive={splitLocation[1] === "ManageCity"}
          />
        </ul>

        <h6 className="menu-title">FAQ’s Management</h6>
        <ul className="left-menu">
          <NavItem
            to="/ManageFaqs"
            icon="assets/img/icon-push-notification.svg"
            text="FAQ’S"
            isActive={splitLocation[1] === "ManageFaqs"}
          />
        </ul>

        <h6 className="menu-title">Content Management </h6>
        <ul className="left-menu">
          <NavItem
            to="/CMS"
            icon="assets/img/icon-push-notification.svg"
            text="CMS"
            isActive={splitLocation[1] === "CMS"}
          />
        </ul>
      </div>
    </aside>
  );
};

const NavItem = ({ to, icon, text, isActive }) => (
  <li className={`nav-item ${isActive ? "active" : ""}`}>
    <Link to={to}>
      <img src={process.env.PUBLIC_URL + `/${icon}`} alt="" title="" />
      {text}
    </Link>
  </li>
);

export default SideBar;
